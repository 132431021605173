import * as React from "react";
import * as d3 from "d3";

const locale = d3.formatLocale({
  decimal: ",",
  thousands: "\u00a0",
  grouping: [3],
  currency: ["", ""],
  minus: "\u2212",
  percent: "\u202f%",
});

const DuoHisto9 = ({ idkey }) => {
  const data = [
    {
      date: "Договор аренды\nобъектов инженерно-\nкоммунального\nназначения",

      2020: 7,
      2021: 13,

      c2020: 64,
      c2021: 20,
    },
    {
      date: "Договор аренды\nземельного участка",

      2020: 22,
      2021: 23,

      c2020: 4_326,
      c2021: 8_844,
    },
    {
      date: "Договор купли-\nпродажи земельного\nучастка, соглашение о\nперераспределении\nземельных участков",

      2020: 2,
      2021: 5,

      c2020: 1101,
      c2021: 2624,
    },
    {
      date: "Договор\nкупли-продажи\nобъектов инженерно-\nкоммунального\nназначения",

      2020: 0,
      2021: 2,

      c2020: 0,
      c2021: 199_074,
    },
    {
      date: "Соглашение\nоб установлении\nсервитута в отношении\nземельного участка",

      2020: 11,
      2021: 14,

      c2020: 943,
      c2021: 1_060,
    },
  ];

  const margin = { top: 70, right: 30, bottom: 130, left: 70 },
    width = 950 - margin.left - margin.right,
    height = 410 - margin.top - margin.bottom;

  React.useEffect(() => {
    const selector = `.HistoOld-${idkey}`;
    document.querySelector(selector).innerHTML = "";
    const svg = d3
      .select(selector)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const subgroups = ["2020", "2021"];

    var groups = d3.map(data, (d) => {
      return d.date;
    });

    var x = d3.scaleBand().domain(groups).range([0, width]).padding([0.2]);

    const wrap = (el) => {
      return d3.axisBottom(x).tickSize(0)(el);
    };

    const insertLinebreaks = (d, i, a) => {
      // console.log(arguments)
      // console.log(this)
      console.log(d, i, a);
      const t = a[i];
      // console.log(t)
      // console.log(t.x)

      var el = d3.select(t);
      var words = d3.select(t).text().split("\n");

      el.text("");
      // console.log(el);
      // console.log(t)
      // console.log(d)
      // console.log(d.sk(d.key))

      for (var i = 0; i < words.length; i++) {
        var tspan = el.append("tspan").text(words[i]);
        if (i > 0) tspan.attr("x", "0").attr("dy", "12");
        if (i == 0) tspan.attr("dy", "0");
      }
    };

    const insertLinebreaks2 = (d, i, a) => {
      // console.log(arguments)
      // console.log(this)
      console.log(d, i, a);
      const t = a[i];
      // console.log(t)
      // console.log(t.x)

      var el = d3.select(t);
      var words = d3.select(t).text().split("\n");

      el.text("");
      // console.log(el);
      // console.log(t)
      console.log(d);
      console.log(d.sk(d.key));
      console.log(d.x + d.sk(d.key));

      for (var i = 0; i < words.length; i++) {
        var tspan = el.append("tspan").text(words[i]);
        if (i > 0)
          tspan
            .attr("x", d.sk(d.key) + xSubgroup.bandwidth() / 2)
            .attr("dy", "12");
        if (i == 0)
          tspan
            .attr("x", d.sk(d.key) + xSubgroup.bandwidth() / 2)
            .attr("dy", "0");
      }
    };

    svg
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(wrap)
      .selectAll("text")
      .each(insertLinebreaks)
      .attr("transform", "translate(0, 52)");

    // .style("text-anchor", "end")
    // .exit()
    svg.select("path.domain").remove();

    var y = d3.scaleLinear().domain([0, 25]).range([height, 0]);
    svg.append("g").call(d3.axisLeft(y).tickFormat(locale.format(",")));
    // TODO: Add lable

    var xSubgroup = d3
      .scaleBand()
      .domain(subgroups)
      .range([0, x.bandwidth()])
      .padding([0.05]);

    var color = d3
      .scaleOrdinal()
      .domain(subgroups)
      .range(["#ccc", "#FFA500", "#cb953e"]);

    svg
      .append("g")
      .selectAll("g")
      .data(data)
      .enter()
      .append("g")
      .attr("transform", (d) => {
        return "translate(" + x(d.date) + ",0)";
      })

      .selectAll("rect")
      .data((d) => {
        return subgroups.map((key) => {
          return { key: key, value: d[key] };
        });
      })
      .enter()
      .append("rect")
      .attr("x", (d) => {
        console.log(xSubgroup(d.key));
        return xSubgroup(d.key);
      })
      .attr("y", (d) => {
        return y(d.value);
      })
      .attr("width", xSubgroup.bandwidth())
      .attr("height", (d) => {
        return height - y(d.value);
      })
      .attr("fill", (d) => {
        return color(d.key);
      })
      .exit() // число внутри столбца
      .data((d) => {
        return subgroups.map((key) => {
          return { key: key, value: d[key] };
        });
      })
      .join("text")
      .attr("x", (d) => {
        if (d.value > 50) return xSubgroup(d.key) + 15;

        return xSubgroup(d.key) + 50;
      })
      .attr("y", (d) => {
        return y(d.value) + 18;
      })
      .text((d) => {
        if (d.value == 0) return "";
        return locale.format("$,")(d.value);
      }) // FIXME: обновить локаль
      .style("font-size", "12px")
      .style("fill", (d) => {
        return "black";
      })
      .style("font-weight", "bold")
      .attr("text-anchor", (d) => {
        return "end";
      })
      .attr("transform", (d) => {
        return `rotate(-90, ${xSubgroup(d.key) + 18}, ${y(d.value) + 12})`;
      })

      // шт.
      .exit()
      .data((d) => {
        return subgroups.map((key) => {
          // console.log(d, key)
          return {
            key: key,
            value: d["c" + key],
            x: x(d.date),
            sk: xSubgroup,
            k: xSubgroup(d["c" + key]),
          };
        });
      })
      .join("text")
      .attr("x", (d, index, arr) => {
        return xSubgroup(d.key);
      })
      .attr("y", (d) => {
        return y(0);
      })
      .text((d) => {
        if (d.value == 0) return "";
        return locale.format("$,")(d.value) + "\nтыс. руб.";
      })
      .attr("class", "subc")
      .style("font-size", "11px")
      .style("fill", (d, index) => {
        return ["#92979c", "#FFA500", "#cb953e"][index];
      })
      .style("font-weight", "bold")
      .style("text-anchor", "middle")
      .exit()
      .append("text");


    svg
      .selectAll(".subc")
      .each(insertLinebreaks2)
      .attr("transform", "translate(0, 22)");
    // .attr("class", "subc")

    // svg.selectAll(".subc").each(insertLinebreaks)
    // .attr("transform", `translate(25,0)`)
    const tsplit = (el) => {
      var words = el.text().split("\n");

      el.text("");

      for (var i = 0; i < words.length; i++) {
        var tspan = el.append("tspan").text(words[i]);
        if (i > 0) tspan.attr("x", "0").attr("dy", "12");
        if (i == 0) tspan.attr("x", "0").attr("dy", "0");
      }
    };

    const ne = "В 2020 году \nне осуществлялось";

    svg
      .append("text")
      .attr("text-anchor", "start")
      .attr("y", 545)
      .attr("dy", ".75em")
      .attr("transform", "rotate(-90, 105, 105)")
      .text(ne)
      .call(tsplit)
      .attr("text-anchor", "start")
      .style("font-size", "12px")
      .style("font-weight", "bold");

    svg
      .append("text")
      .attr("text-anchor", "end")
      .attr("y", 6)
      .attr("dy", ".75em")
      .attr("transform", "rotate(-90)")
      .text("Количество")
      .style("font-size", "11px");
  }, [idkey]);

  const ldata = [
    {
      label: "2020",
      color: "",
    },
    {
      label: "2021",
      color: "",
    },
  ];
  const lcolor = ["bg-[#ccc]", "bg-[#FFA500]", "bg-[#cb953e]"];

  return (
    <>
      <div className={`HistoOld-${idkey}`}></div>
      <div className="max-w-4xl m-auto">
        <ul className="list-none pl-0 text-300 my-5 text-sm flex flex-row gap-3">
          {ldata.map((el, i) => (
            <li key={i}>
              <span className={`legend-pseudo ${lcolor[i]}`}></span>
              {el.label}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default DuoHisto9;
