import * as React from "react"
import * as d3 from "d3"

const getNextColor = colors => {
  let i = 0

  return () => {
    i++
    if (i === colors.length) {
      i = 0
    }
    return colors[i]
  }
}

const locale = d3.formatLocale({
  decimal: ",",
  thousands: "\u00a0",
  grouping: [3],
  currency: ["", ""],
  minus: "\u2212",
  percent: "\u202f%",
})

const DuoHisto1 = ({ idkey }) => {
  const data = [
    {
      date: "Аренда\nзданий",

      2020: 0.0,
      2021: 488.7,

      c2020: 0,
      c2021: 2,
    },
    {
      date: "Аренда\nпомещений",

      2020: 1003.3,
      2021: 1003.3,

      c2020: 3,
      c2021: 3,
    },
    {
      date: "Здания\nгазорегуляторных\nпунктов (ГРП)\nв собственности",

      2020: 11_377.8,
      2021: 13_294.8,

      c2020: 243,
      c2021: 239,
    },
    {
      date: "Административные\nздания (помещения)\nв собственности",

      2020: 78_273.4,
      2021: 99_459.6,

      c2020: 89,
      c2021: 90,
    },
  ]

  const margin = { top: 70, right: 30, bottom: 70, left: 70 },
    width = 650 - margin.left - margin.right,
    height = 450 - margin.top - margin.bottom

  React.useEffect(() => {
    const selector = `.HistoOld-${idkey}`
    document.querySelector(selector).innerHTML = ""
    const svg = d3
      .select(selector)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`)

    const subgroups = ["2020", "2021"]

    var groups = d3.map(data, d => {
      return d.date
    })

    var x = d3.scaleBand().domain(groups).range([0, width]).padding([0.2])

    const wrap = el => {
      console.log(el)
      console.log(d3.axisBottom(x))
      return d3.axisBottom(x).tickSize(0)(el)
    }

    const insertLinebreaks = (d, i, a) => {
      // console.log(arguments)
      // console.log(this)
      const t = a[i]
      var el = d3.select(t)
      var words = d3.select(t).text().split("\n")

      el.text("")

      for (var i = 0; i < words.length; i++) {
        var tspan = el.append("tspan").text(words[i])
        if (i > 0) tspan.attr("x", 0).attr("dy", "12")
      }
    }

    svg
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(wrap)
      .selectAll("text")
      .each(insertLinebreaks)
      .attr("transform", "translate(0, 22)")
    // .style("text-anchor", "end")

    var y = d3.scaleLinear().domain([0, 120_000]).range([height, 0])
    svg.append("g").call(d3.axisLeft(y).tickFormat(locale.format(",")))
    // TODO: Add lable

    var xSubgroup = d3
      .scaleBand()
      .domain(subgroups)
      .range([0, x.bandwidth()])
      .padding([0.05])

    var color = d3
      .scaleOrdinal()
      .domain(subgroups)
      .range(["#ccc", "#FFA500", "#cb953e"])

    svg
      .append("g")
      .selectAll("g")
      .data(data)
      .enter()
      .append("g")
      .attr("transform", d => {
        return "translate(" + x(d.date) + ",0)"
      })

      .selectAll("rect")
      .data(d => {
        return subgroups.map(key => {
          return { key: key, value: d[key] }
        })
      })
      .enter()
      .append("rect")
      .attr("x", d => {
        console.log(xSubgroup(d.key))
        return xSubgroup(d.key)
      })
      .attr("y", d => {
        return y(d.value)
      })
      .attr("width", xSubgroup.bandwidth())
      .attr("height", d => {
        return height - y(d.value)
      })
      .attr("fill", d => {
        return color(d.key)
      })
      .exit()
      .data(d => {
        return subgroups.map(key => {
          return { key: key, value: d[key] }
        })
      })
      .join("text")
      .attr("x", d => {
        return xSubgroup(d.key) + 15
      })
      .attr("y", d => {
        return y(d.value) + 18
      })
      .text(d => {
        // if (d.value == 0) return ""
        return locale.format("$,")(d.value)
      }) // FIXME: обновить локаль
      .style("font-size", "12px")
      .style("fill", d => {
        return "black"
      })
      .style("font-weight", "bold")
      // .attr("text-anchor", d => {
      //   return "end"
      // })
      // .attr("transform", d => {
      //   return `rotate(0, ${xSubgroup(d.key) - 30}, ${y(d.value)})`
      // })
      .attr("transform", d => {
        return `rotate(-90, ${xSubgroup(d.key) + 10}, ${y(d.value)})`
      })

      // шт.
      .exit()
      .data(d => {
        return subgroups.map(key => {
          // console.log(d, key)
          return { key: key, value: d["c" + key] }
        })
      })
      .join("text")
      .attr("x", (d, index, arr) => {
        // console.log(xSubgroup.bandwidth())
        // console.log('index', index)
        // console.log('arr', arr)
        // console.log('key', d.key)
        return xSubgroup(d.key)
      })
      .attr("y", d => {
        return y(0) + 15
      })
      .text(d => {
        if (d.value == 0) return ""
        return locale.format("$,")(d.value) + " шт."
      }) // FIXME: обновить локаль
      .style("font-size", "11px")
      .style("fill", (d, index) => {
        return ["#92979c", "#FFA500", "#cb953e"][index]
      })
      .style("font-weight", "bold")
      // .style('text-anchor', 'start')
      .style("text-anchor", "middle")
      // .style('text-anchor', 'end')
      .attr("transform", `translate(25,0)`)

    svg
      .append("text")
      // .attr("class", "y label")
      .attr("text-anchor", "end")
      .attr("y", 6)
      .attr("dy", ".75em")
      .attr("transform", "rotate(-90)")
      .text("кв. м")
      .style("font-size", "11px")

  }, [idkey])

  const ldata = [
    {
      label: "2020",
      color: "",
    },
    {
      label: "2021",
      color: "",
    },
  ]
  const lcolor = ["bg-[#ccc]", "bg-[#FFA500]", "bg-[#cb953e]"]
  const getLegendColor = getNextColor(lcolor)

  return (
    <>
      <div className={`HistoOld-${idkey}`}></div>
      <div className="max-w-4xl m-auto">
        <ul className="list-none pl-0 text-300 my-5 text-sm flex flex-row gap-3">
          {ldata.map((el, i) => (
            <li key={i}>
              <span className={`legend-pseudo ${lcolor[i]}`}></span>
              {el.label}
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}

export default DuoHisto1
