import * as React from "react";
import Hero from "../components/hero";
import Tail from "../components/tail";
import TextBlock from "../components/text";
import Seo from "../components/seo";
import Header2 from "../components/header/header2";
import Header4 from "../components/header/header4";
import MGCaption from "../components/MGCaption";
import DuoHisto1 from "../components/histograms/duohisto1";
import DuoHisto2 from "../components/histograms/duohisto2";
import { StaticImage } from "gatsby-plugin-image";
import DuoHisto3 from "../components/histograms/duohisto3";
import DuoHisto4 from "../components/histograms/duohisto4";
import DuoHisto5 from "../components/histograms/duohisto5";
import DuoHisto6 from "../components/histograms/duohisto6";
import DuoHisto7 from "../components/histograms/duohisto7";
import DuoHisto8 from "../components/histograms/duohisto8";
import DuoHisto9 from "../components/histograms/duohisto9";
import NaviNext from "../components/NaviNext";
import SinglePhoto from "../components/SinglePhoto";

const Part6 = () => {
  return (
    <Tail>
      <Hero>
        <h1>РАЗДЕЛ 6. <br/>ИНФОРМАЦИЯ ОБ ИМУЩЕСТВЕННОМ КОМПЛЕКСЕ</h1>
      </Hero>

      <TextBlock>
        <p>
          Имущественный комплекс АО «МОСГАЗ» состоит преимущественно из
          газопроводов высокого, среднего и низкого давлений, а также зданий,
          помещений и земельных участков, в совокупности обеспечивающих надлежа-
          щее функционирование газораспределительной системы в целях
          транспортировки и подачи газа непосред- ственно потребителям.
        </p>
        <p>
          Имущественный комплекс расположен в большей своей части на территории
          города Москвы, незначительная его часть расположена на территории
          Московской области.
        </p>
        <p>
          Имущественный комплекс не обременен залогом, арендой, не передан
          полностью или частично в довери- тельное управление, не состоит под
          арестом.
        </p>

        <Header2>6.1. СВЕДЕНИЯ О ЛИНЕЙНЫХ ОБЪЕКТАХ</Header2>

        <p className="mb-1">
          По состоянию на 31.12.2021 общая протяженность газовых сетей,
          принадлежащих Обществу, составляет 7 267,36 км, из них:
        </p>
        <ul className="list-disc list-outside">
          <li>
            <p>подземные газопроводы – 3 757,85 км;</p>
          </li>
          <li>
            <p>надземные газопроводы – 3 509,51 км.</p>
          </li>
        </ul>

        <SinglePhoto>
          <StaticImage
            src="../images/part-06/6.1.line.jpg"
            loading="eager"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt=""
          />
        </SinglePhoto>

        <p className="mb-1">
          По состоянию на 31.12.2021 Обществом оформлены права аренды и продлены
          сроки действия заключенных договор аренды на газопроводы,
          принадлежащие городу Москве, общей протяженностью 83,89 км и
          ГРПБ/ШБДГ/ЭЗУ, из них:
        </p>
        <ul className="list-disc list-outside">
          <li>
            <p>подземные газопроводы – 80,61 км;</p>
          </li>
          <li>
            <p>надземные газопроводы – 3,28 км;</p>
          </li>
          <li>
            <p>ГРПБ/ШБДГ/ЭЗУ – 23 шт.</p>
          </li>
        </ul>

        <Header2>6.2. СВЕДЕНИЯ О НЕЖИЛЫХ ЗДАНИЯХ И ПОМЕЩЕНИЯХ</Header2>

        <p>
          В хозяйственной деятельности Общество использует здания и помещения
          административного назначения для размещения управленческого персонала
          и производственных служб, а также здания и помещения производ-
          ственного назначения – газорегуляторные пункты (ГРП).
        </p>

        <p>
          Большая часть зданий и помещений принадлежит Обществу на праве
          собственности. Три помещения админи- стративного назначения, здание
          газорегуляторного пункта и здание узла учета расхода газа принадлежат
          Обществу на праве аренды.
        </p>

        <p className="mb-1">
          По состоянию на 31.12.2021 в собственности Общества находится 329
          объектов недвижимости общей площа- дью 112 754,4 кв. м. из них:
        </p>
        <ul className="list-disc list-outside">
          <li>
            <p>
              здания газорегуляторных пунктов (ГРП) – 239 шт. площадью 13 294,8
              кв. м;
            </p>
          </li>
          <li>
            <p>
              административные здания (помещения) – 90 шт. площадью 99 459,6 кв.
              м.
            </p>
          </li>
        </ul>

        <p>
          Общество арендует 3 помещения общей площадью 1003,3 кв. м. для
          размещения структурных подразделений и производственных объектов, а
          именно: нежилое помещение общей площадью 560,1 кв. м (арендодатель:
          ГБУ «Гормост»); нежилое помещение общей площадью 426,9 кв. м
          (арендодатель: ГБУ «ЭВАЖД»); нежилое помеще- ние общей площадью 16,3
          кв. м (арендодатель: ПАО «МОЭК»); и 2 здания общей площадью 488,7 кв.
          м для размещения оборудования газоснабжения, а именно: здание
          газорегуляторного пункта общей площадью 384,1 кв. м. и здание узла
          учета расхода газа общей площадью 104,6 кв. м (арендодатель
          Департамент городского имущества г. Москвы).
        </p>

        <p>
          По 35 объектам недвижимости Обществом ведется работа по оформлению
          имущественных прав.
        </p>

        <Header4>
          Информация об объектах капитального строительства, используемых в
          хозяйственной деятельности, по виду права с учетом прироста за 2021
          год:
        </Header4>

        <MGCaption>Нежилые здания и помещения Общества</MGCaption>

        <DuoHisto1 idkey="dh1"></DuoHisto1>

        <Header2>6.3. СВЕДЕНИЯ О ЗЕМЕЛЬНЫХ УЧАСТКАХ</Header2>

        <MGCaption>
          Земельные участки, используемые Обществом в 2020 и 2021 гг.
        </MGCaption>
        <div className="flex flex-col md:flex-row">
          <div className="flex flex-col">
            <DuoHisto2 idkey="dh2"></DuoHisto2>
          </div>
          <div className="md:mt-20 lg:mt-25">
            <p className="mb-1">
              По состоянию на 31.12.2021 Общество использует 796 земельных
              участков под размещение административных, производственных и
              складских зданий, газорегуляторных пунктов и выходящих на
              поверхность инженерных сооружений линейных объектов, а также
              необходимых для строительства и реконструкции газопроводов и
              других линейных объектов, общей площадью 850 858 кв. м, из них:
            </p>
            <ul className="list-disc list-outside">
              <li>
                <p>
                  в собственности Общества находится 179 земельных участков
                  общей площадью 168 131 кв. м,
                </p>
              </li>
              <li>
                <p>
                  в пользовании – 617 земельных участков общей площадью
                  682 727 кв. м.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <MGCaption>
          Земельные участки, находящиеся в собственности и в пользовании в 2020
          и 2021 гг.
        </MGCaption>

        <DuoHisto3 idkey={"duo3"}></DuoHisto3>

        <div className="mt-12">
          <div className="max-w-[200px] sm:max-w-[350px] mb-5 ml-10 md:float-right">
            <StaticImage
              src="../images/part-06/6.3.jpg"
              loading="eager"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt=""
            />
          </div>
          <p className="mb-1">
            В пользовании у Общества (аренда, сервитут, раз- решение на
            использование/размещение) находится 617 земельных участков общей
            площадью 682 727 кв.м, в том числе:
          </p>
          <ul className="list-disc list-outside">
            <li>
              <p>
                для размещения зданий ГРП, административных, производственных и
                складских зданий (помещений) – 269 земельных участков общей
                площадью 195 468 кв. м;
              </p>
            </li>
            <li>
              <p>
                для размещения газопроводов – 322 земельных участков площадью
                486 472 кв. м, в том числе в ТиНАО – 140 земельных участков
                площадью 327 989 кв. м;
              </p>
            </li>
            <li>
              <p>
                для размещения выходящих на поверхность инже- нерных сооружений
                линейных объек- тов – 26 земельных участков площадью 787 кв. м.
              </p>
            </li>
          </ul>
        </div>

        <MGCaption>
          Земельные участки, используемые для размещения зданий ГРП,
          административных, производственных и складских зданий (помещений), с
          учетом прироста за 2021 год
        </MGCaption>

        <DuoHisto4 idkey={"duo4"}></DuoHisto4>

        <MGCaption>
          Земельные участки, используемые для размещения газопроводов по виду
          права в 2020 и 2021 гг.
        </MGCaption>

        <DuoHisto5 idkey={"duo5"}></DuoHisto5>

        <MGCaption>
          Земельные участки, используемые для размещения газопроводов по виду
          права на территории ТиНАО в 2020 и 2021 гг.
        </MGCaption>

        <DuoHisto6 idkey={"duo6"}></DuoHisto6>

        <MGCaption>
          Земельные участки, используемые для размещения выходящих на
          поверхность инженерных сооружений линейных объектов в 2020 и 2021 гг.
        </MGCaption>

        <div className="flex flex-col md:flex-row">
          <div className="flex flex-col">
            <DuoHisto7 idkey="dh7"></DuoHisto7>
          </div>
          <div className="md:mt-20 lg:mt-25">
            <p>
              По состоянию на 31.12.2021 ведется работа по оформлению 61
              земельного участка, необходимых для эксплуатации зданий, а также в
              отношении 230 земельных участков, необходимых для строительства и
              реконструкции газопроводов и размещения выходящих на поверхность
              инженерных сооружений линейных объектов.
            </p>
          </div>
        </div>

        <Header2>
          6.4. СВЕДЕНИЯ О СОВЕРШЕННЫХ ОБЩЕСТВОМ СДЕЛКАХ С НЕДВИЖИМЫМ ИМУЩЕСТВОМ
        </Header2>

        <p>
          В 2021 году Обществом совершено 177 сделки по приобретению в
          собственность и аренду земельных участ- ков в целях эксплуатации
          зданий и помещений, надземных и подземных газопроводов, а также их
          наземных частей.
        </p>

        <p>
          В отношении земельных участков, необходимых в целях проведения работ
          по строительству и реконструк- ции газопроводов высокого давления,
          Обществом заключались соглашения об установлении сервитута.
        </p>

        <p>
          Кроме того, с целью компенсации убытков Общества, возникающих в
          случаях переустройства (переклад- ки, выноса из зоны застройки)
          принадлежащего Обществу имущества при ведении градостроительной
          деятельности, Обществом заключались соглашения, предусматривающие
          получение Обществом компенса- ции в денежной форме.
        </p>

        <MGCaption>
          Сделки доходные, совершенные с недвижимым имуществом за 2020 и 2021
          гг.
        </MGCaption>

        <DuoHisto8 idkey={"duo8"}></DuoHisto8>

        <MGCaption>
          Сделки расходные, совершенные с недвижимым имуществом за 2020 и 2021
          гг.
        </MGCaption>

        <DuoHisto9 idkey={"duo9"}></DuoHisto9>

        <NaviNext
          next="/part-7"
          title="Раздел 7. КАДРОВАЯ И СОЦИАЛЬНАЯ ПОЛИТИКА"
        />
      </TextBlock>
    </Tail>
  );
};

export default Part6;

export const Head = () => (
  <Seo title="РАЗДЕЛ 6. ИНФОРМАЦИЯ ОБ ИМУЩЕСТВЕННОМ КОМПЛЕКСЕ" />
);
